import { api } from "@core/services/nocd-api";
import { AxiosError } from "axios";
import { useQuery, UseQueryResult } from "react-query";

interface ReassuranceCheckResponse {
  prompt_for_initial_reassurance_pledge: boolean;
  prompt_for_reassurance_pledge: boolean;
}
const useReassuranceCheck = (
  accessToken: string
): UseQueryResult<ReassuranceCheckResponse, AxiosError> =>
  useQuery(
    ["reassuranceCheck", accessToken],
    async ({ signal }) => {
      return api
        .get<ReassuranceCheckResponse>("/v1/screen_launch/new_post", {
          headers: { Authorization: accessToken },
          signal,
        })
        .then(({ data }) => data)
        .catch((err) => console.warn(err));
    },
    {
      enabled: !!accessToken,
      refetchInterval: false,
    }
  );

export default useReassuranceCheck;
