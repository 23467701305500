import Modal from "@core/ui/Modal";
import Image from "next/image";

interface Props {
  isOpen: boolean;
  closeWithoutAccepting: () => void;
  acceptReassuranceCheck: () => void;
  variant: "initial" | "general";
}

export default function ReassuranceModal({
  isOpen,
  closeWithoutAccepting,
  acceptReassuranceCheck,
  variant,
}: Props): JSX.Element {
  return (
    <Modal
      isOpen={isOpen}
      onClose={() => closeWithoutAccepting()}
      className="relative overflow-hidden pt-33 tablet:pt-38"
    >
      <Image
        src="/images/community/reassurance-modal-bg.png"
        layout="fill"
        objectFit="cover"
        objectPosition="center top"
        quality={100}
        alt=""
      />

      <Modal.CloseButton
        onClick={() => closeWithoutAccepting()}
        className="absolute z-1 top-4 right-4"
      />

      <div className="relative z-1">
        {variant === "initial" ? (
          <div>
            <h2 className="text-center font-bold">
              A note on seeking reassurance
            </h2>

            <p className="my-4">
              Sometimes we turn to others to help ease our doubts and confirm
              what feels safe. This is known as{" "}
              <strong>seeking reassurance</strong>. While it&aops;s a natural
              response, for individuals with OCD, it can become a harmful
              compulsive behavior. <br />
              To engage in a healthy way, please agree to the following:
            </p>

            <ul>
              {[
                `I won't ask others to tell me I'm okay`,
                `I won't ask others to make decisions for me`,
                `I won't try to get someone to disprove something I fear`,
                `If I notice these tendencies on myself, I'll seek support and practice ERP`,
              ].map((i) => (
                <li key={`${i}-initial`}>
                  <div className="flex items-start gap-2">
                    <div className="pt-2">
                      <svg
                        width="11"
                        height="10"
                        viewBox="0 0 11 10"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M4.90962 9.06531C4.41787 9.60937 3.61997 9.60937 3.12847 9.06531L0.368809 6.01207C-0.122936 5.46828 -0.122936 4.5855 0.368809 4.0417C0.860316 3.49764 1.65821 3.49764 2.14996 4.0417L3.79417 5.86057C3.9183 5.99763 4.11979 5.99763 4.24415 5.86057L8.69619 0.934899C9.1877 0.390841 9.9856 0.390841 10.4773 0.934899C10.7135 1.19616 10.8462 1.55064 10.8462 1.92009C10.8462 2.28953 10.7135 2.64401 10.4773 2.90527L4.90962 9.06531Z"
                          fill="#1E293B"
                        />
                      </svg>
                    </div>
                    <div>{i}</div>
                  </div>
                </li>
              ))}
            </ul>

            <div className="my-4 flex justify-center">
              <a
                href={`${process.env.NEXT_PUBLIC_WEBSITE_BASE_URL}/blog/steps-to-break-the-reassurance-seeking-pattern`}
                target="_blank"
                rel="noreferrer"
                className="font-bold text-indigo-600"
              >
                Learn more &rarr;
              </a>
            </div>

            <button
              type="button"
              onClick={() => acceptReassuranceCheck()}
              className="block font-semibold w-full max-w-screen-small-phone mx-auto p-4 rounded-xl bg-indigo-600 text-white"
            >
              Agree and continue
            </button>
          </div>
        ) : null}

        {variant === "general" ? (
          <div>
            <h2 className="text-center font-bold">
              Before you post, a quick check-in
            </h2>

            <p className="my-6">
              As you go about posting in the community, it&apos;s understandable
              you may have the urge to <strong>seek reassurance</strong>. Doing
              so prevents you from learning to tolerate distress and can make
              you feel stuck.
              <br /> To engage in a healthy way, please agree to the following:
            </p>

            <ul className="my-6">
              {[
                `I won't ask others to tell me I'm okay`,
                `I won't ask others to make decisions for me`,
                `I won't try to get someone to disprove something I fear`,
                `If I notice these tendencies on myself, I'll seek support and practice ERP`,
              ].map((i) => (
                <li key={`${i}-initial`}>
                  <div className="flex items-start gap-2">
                    <div className="pt-2">
                      <svg
                        width="11"
                        height="10"
                        viewBox="0 0 11 10"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M4.90962 9.06531C4.41787 9.60937 3.61997 9.60937 3.12847 9.06531L0.368809 6.01207C-0.122936 5.46828 -0.122936 4.5855 0.368809 4.0417C0.860316 3.49764 1.65821 3.49764 2.14996 4.0417L3.79417 5.86057C3.9183 5.99763 4.11979 5.99763 4.24415 5.86057L8.69619 0.934899C9.1877 0.390841 9.9856 0.390841 10.4773 0.934899C10.7135 1.19616 10.8462 1.55064 10.8462 1.92009C10.8462 2.28953 10.7135 2.64401 10.4773 2.90527L4.90962 9.06531Z"
                          fill="#1E293B"
                        />
                      </svg>
                    </div>
                    <div>{i}</div>
                  </div>
                </li>
              ))}
            </ul>

            <div className="my-4 flex justify-center">
              <a
                href={`${process.env.NEXT_PUBLIC_WEBSITE_BASE_URL}/blog/steps-to-break-the-reassurance-seeking-pattern`}
                target="_blank"
                rel="noreferrer"
                className="font-bold text-indigo-600"
              >
                Learn more &rarr;
              </a>
            </div>

            <button
              type="button"
              onClick={() => acceptReassuranceCheck()}
              className="block font-semibold w-full max-w-screen-small-phone mx-auto p-4 rounded-xl bg-indigo-600 text-white"
            >
              Agree and continue
            </button>
          </div>
        ) : null}
      </div>
    </Modal>
  );
}
